import { Environment } from './environment';

export const local: Environment = {
    production: false,
    apiUrl: 'https://localhost:44375/api',
    googleAnalyticsId: '',
    authorizationConfig: {
        ADFS: {
            metadataUrl: 'https://ppgadfsproxytest.azurewebsites.net/testsso4.ppg.com/adfs/.well-known/openid-configuration',
            stsServerUrl: 'https://testsso4.ppg.com/adfs/',
            clientId: 'https://devpackagingtdsspa.z20.web.core.windows.net/',
            scope: 'openid',
            resourceId: 'https://devpackagingtdsapi.azurewebsites.net/',
            renewTokenSecondsOffset: 3570,
            passResetUrl: '',
            passResetPolicy: '',
            passResetScope: '',
            passResetResponseType: ''
        },
        ADB2C: {
            metadataUrl: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/' +
                'v2.0/.well-known/openid-configuration?p=B2C_1_PackagingTDS_SignUpSignIn_v2',
            stsServerUrl: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/',
            clientId: '038b3f6f-f63e-44d1-90c4-322dc6fcc4ae',
            scope: 'openid https://ppgindustriesincb2cdev.onmicrosoft.com/api/user_impersonation',
            resourceId: null,
            renewTokenSecondsOffset: 270,
            passResetUrl: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/oauth2/v2.0/authorize',
            passResetPolicy: 'B2C_1_PackagingTDS_Password_Reset_v2',
            passResetScope: 'openid',
            passResetResponseType: 'id_token'
        },
        common: {
            redirectUrl: window.location.origin,
            responseType: 'id_token token',
            postLogoutRedirectUrl: `${window.location.origin}/#/sign-in?authtype=b2c`,
            postLoginRoute: '/products',
            forbiddenRoute: '/error/forbidden',
            unauthorizedRoute: '/error/unauthorized',
            autoUserInfo: false,
            enableLogs: true,
            maxIdTokenIATOffsetAllowedInSeconds: 30,
            storage: localStorage,
            renewToken: true,
            renewTokenUrl: window.location.origin
        }
    }
};

export const dev: Environment = {
    production: false,
    apiUrl: 'https://devpackagingtdsapi.azurewebsites.net/api',
    googleAnalyticsId: '',
    authorizationConfig: {
        ADFS: {            
            //metadataUrl: 'https://testsso4.ppg.com/adfs/.well-known/openid-configuration',
            //stsServerUrl: 'https://testsso4.ppg.com/adfs/',
            metadataUrl: 'https://login.microsoftonline.com/9dfd1e5e-bb06-40df-8eeb-2a295fe4dda5/.well-known/openid-configuration',
            stsServerUrl: 'https://sts.windows.net/9dfd1e5e-bb06-40df-8eeb-2a295fe4dda5/',
            clientId: 'cdc58565-c3e9-421b-a589-2dc81505d6f7',
            scope: 'openid',
            resourceId: 'https://devpackagingtdsapi.azurewebsites.net/',
            renewTokenSecondsOffset: 3570,
            passResetUrl: '',
            passResetPolicy: '',
            passResetScope: '',
            passResetResponseType: ''
            
            //tenantName: 'PPGIndustriesInc'
        },
        ADB2C: {
            metadataUrl: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/' +
                'v2.0/.well-known/openid-configuration?p=B2C_1_PackagingTDS_SignUpSignIn_v2',
            stsServerUrl: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/',
            clientId: '038b3f6f-f63e-44d1-90c4-322dc6fcc4ae',
            scope: 'openid https://ppgindustriesincb2cdev.onmicrosoft.com/api/user_impersonation',
            resourceId: null,
            renewTokenSecondsOffset: 270,
            passResetUrl: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/oauth2/v2.0/authorize',
            passResetPolicy: 'B2C_1_PackagingTDS_Password_Reset_v2',
            passResetScope: 'openid',
            passResetResponseType: 'id_token'
        },
        common: {
            redirectUrl: window.location.origin,
            responseType: 'id_token token',
            postLogoutRedirectUrl: `${window.location.origin}/#/sign-in?authtype=b2c`,
            postLoginRoute: '/products',
            forbiddenRoute: '/error/forbidden',
            unauthorizedRoute: '/error/unauthorized',
            autoUserInfo: false,
            enableLogs: true,
            maxIdTokenIATOffsetAllowedInSeconds: 30,
            storage: localStorage,
            renewToken: true,
            renewTokenUrl: window.location.origin
        }
    }
};

export const test: Environment = {
    production: false,
    apiUrl: 'https://testpackagingtdsapi.azurewebsites.net/api',
    googleAnalyticsId: '',
    authorizationConfig: {
        ADFS: {
            metadataUrl: 'https://testsso4.ppg.com/adfs/.well-known/openid-configuration',
            stsServerUrl: 'https://testsso4.ppg.com/adfs/',
            clientId: 'https://testpackagingtdsspa.z20.web.core.windows.net/',
            scope: 'openid',
            resourceId: 'https://testpackagingtdsapi.azurewebsites.net/',
            renewTokenSecondsOffset: 3570,
            passResetUrl: '',
            passResetPolicy: '',
            passResetScope: '',
            passResetResponseType: ''
        },
        ADB2C: {
            metadataUrl: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/' +
                'v2.0/.well-known/openid-configuration?p=B2C_1_PackagingTDS_SignUpSignIn_v2',
            stsServerUrl: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/',
            clientId: '038b3f6f-f63e-44d1-90c4-322dc6fcc4ae',
            scope: 'openid https://ppgindustriesincb2cdev.onmicrosoft.com/api/user_impersonation',
            resourceId: null,
            renewTokenSecondsOffset: 270,
            passResetUrl: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/oauth2/v2.0/authorize',
            passResetPolicy: 'B2C_1_PackagingTDS_Password_Reset_v2',
            passResetScope: 'openid',
            passResetResponseType: 'id_token'
        },
        common: {
            redirectUrl: window.location.origin,
            responseType: 'id_token token',
            postLogoutRedirectUrl: `${window.location.origin}/#/sign-in?authtype=b2c`,
            postLoginRoute: '/products',
            forbiddenRoute: '/error/forbidden',
            unauthorizedRoute: '/error/unauthorized',
            autoUserInfo: false,
            enableLogs: true,
            maxIdTokenIATOffsetAllowedInSeconds: 30,
            storage: localStorage,
            renewToken: true,
            renewTokenUrl: window.location.origin
        }
    }
};

export const prd: Environment = {
    production: true,
    apiUrl: 'https://packagingtdsapi.azurewebsites.net/api',
    //googleAnalyticsId: 'UA-168212728-1',
    googleAnalyticsId: '',
    authorizationConfig: {
        ADFS: {
            metadataUrl: 'https://sso4.ppg.com/adfs/.well-known/openid-configuration',
            stsServerUrl: 'https://sso4.ppg.com/adfs/',
            clientId: 'https://packagingtdsspa.z20.web.core.windows.net/',
            scope: 'openid',
            resourceId: 'https://packagingtdsapi.azurewebsites.net/',
            renewTokenSecondsOffset: 0,
            passResetUrl: '',
            passResetPolicy: '',
            passResetScope: '',
            passResetResponseType: ''
        },
        ADB2C: {
            metadataUrl: 'https://ppgindustriesincb2cprod.b2clogin.com/ppgindustriesincb2cprod.onmicrosoft.com/' +
                'v2.0/.well-known/openid-configuration?p=B2C_1_PackagingTDS_SignUpSignIn_v2',
            stsServerUrl: 'https://ppgindustriesincb2cprod.b2clogin.com/ppgindustriesincb2cprod.onmicrosoft.com/',
            clientId: 'b66a5c8c-6a54-4b80-a3cb-beadb0f04d09',
            scope: 'openid https://ppgindustriesincb2cprod.onmicrosoft.com/packagingtdsapi/user_impersonation',
            resourceId: null,
            renewTokenSecondsOffset: 270,
            passResetUrl: 'https://ppgindustriesincb2cprod.b2clogin.com/ppgindustriesincb2cprod.onmicrosoft.com/oauth2/v2.0/authorize',
            passResetPolicy: 'B2C_1_PackagingTDS_Password_Reset_v2',
            passResetScope: 'openid',
            passResetResponseType: 'id_token'
        },
        common: {
            redirectUrl: window.location.origin,
            responseType: 'id_token token',
            postLogoutRedirectUrl: `${window.location.origin}/#/sign-in?authtype=b2c`,
            postLoginRoute: '/products',
            forbiddenRoute: '/error/forbidden',
            unauthorizedRoute: '/error/unauthorized',
            autoUserInfo: false,
            enableLogs: true,
            maxIdTokenIATOffsetAllowedInSeconds: 30,
            storage: localStorage,
            renewToken: true,
            renewTokenUrl: window.location.origin
        }
    }
};
