import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '../../../../node_modules/@angular/forms';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {
  // 3 seconds as default
  @Input() timeoutMillis = 3000;
  @Output() typingEnded: EventEmitter<any> = new EventEmitter();
  private call: any = null;
  public searchFieldGroup: FormGroup;
  private searchField: AbstractControl;

  constructor(private formBuilder: FormBuilder) {
    this.searchFieldGroup = this.formBuilder.group({
      search: ['']
    });
    this.searchField = this.searchFieldGroup.controls.search;
  }

  ngOnInit() { }

  private cancelCall() {
    if (this.call !== null) {
      clearTimeout(this.call);
    }
  }

  onKeyUp() {
    this.cancelCall();

    this.call = setTimeout(() => {
      this.typingEnded.emit([this.searchField.value]);
      this.call = null;
    }, this.timeoutMillis);
  }

  clearField() {
    this.cancelCall();

    if (this.searchField.value !== '') {
      this.searchField.setValue('');
      this.typingEnded.emit([this.searchField.value]);
    }
  }

  public getValue(): string {
    return this.searchField.value;
  }

  public setValue(value: string) {
      this.searchField.setValue(value);
  }
}
