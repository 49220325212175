import { Injectable } from '../../../node_modules/@angular/core';
import { ServiceBase } from './ServiceBase';
import { Observable } from '../../../node_modules/rxjs';
import { EnvironmentService } from './EnvironmentService';

@Injectable()
export class DocumentService {
    constructor(
      private serviceBase: ServiceBase,
      private environmentService: EnvironmentService,
    ) { }

    getApplicationDataSheetFile(productId: string, runConditionId: string, lang: string): Observable<Blob> {
        return this.serviceBase.getBlob(`/document/ads/${productId}?runConditionId=${runConditionId}&lang=${lang}`);
    }

    getProductDataSheetFile(productId: string, lang: string): Observable<Blob> {
        return this.serviceBase.getBlob(`/document/pds/${productId}?lang=${lang}`);
    }

    getSafetyDataSheetUrl(productId: string, lang: string) {
      let env: string;
      this.environmentService.getEnvironment().production ? env = '' : env = 'stg';
      const langCodes = lang.split('-');
      productId = productId.split(' ')[0];
      lang = `${langCodes[0]}-${langCodes[1].toUpperCase()}`;
      // tslint:disable-next-line:max-line-length
      return `https://${env}buyat.ppg.com/EHSDocumentManagerPublic/documentSearch.aspx?FolderID1=87&SearchAllPublicFolders=True&CodeCondition=BeginsWith&CodeValue=${productId}&SuppressSearchControls=True`;
    }

   getSafetyDataSheetUrlSDS(id: string, productId: string, lang: string) {
      let env: string;
      this.environmentService.getEnvironment().production ? env = '' : env = 'stg';
      const langCodes = lang.split('-');
      productId = productId.split(' ')[0];
      lang = `${langCodes[0]}-${langCodes[1].toUpperCase()}`;

      return this.serviceBase.get<boolean>(`/product/ValidateSds/${id}`);
    }

    getEnvUrl() {
      let env: string;
      this.environmentService.getEnvironment().production ? env = '' : env = 'stg';

      return env;
    }

    getVocFile(productId: string): Observable<Blob> {
        return this.serviceBase.getBlob(`/document/voc/${productId}`);
    }

    getCoaFile(parentItem: string, lotNumber: string): Observable<Blob> {
      return this.serviceBase.getBlob(`/document/coa/${parentItem}?lotNumber=${lotNumber}`);
    }
}
