export enum Languages {
  English = 'en-us',
  German = 'de-de',
  French = 'fr-fr',
  Italian = 'it-it',
  'Spanish (Mexico)' = 'es-mx',
  'Spanish (Spain)' = 'es-es',
  'Portuguese (Brazil)' = 'pt-br',
  Russian = 'ru-ru',
  Polish = 'pl-pl',
  Chinese = 'zh-cn',
  'Chinese (Traditional)' = 'zh-tw',
  Thai = 'th-th',
  'Korean (South Korea)' = 'ko-kr',
  Indonesian = 'id-id',
  Vietnamese = 'vi-vn'
}
