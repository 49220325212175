import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/User';
import { UserService } from '../../services/UserService';
import { EnvironmentService } from '../../services/EnvironmentService';
import { AuthService } from 'src/app/services/AuthService';
import { Subscription } from 'rxjs';
import { Languages } from 'src/app/models/Languages.enum';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  public loggedIn = false;
  private subscription: Subscription;

  @Input()
  public user: User = null;
  public languages = Languages;

  @Output() logout: EventEmitter<any> = new EventEmitter();
  public isReady = false;
  public envService: EnvironmentService;
  selectedLanguage = 'English';

  keys = Object.keys;

  constructor(
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private userService: UserService,
    private envServ: EnvironmentService) {
      this.envService = this.envServ;
    }


  public isProductsLinkActive(): boolean {
    return this.router.url === '/products' || this.router.url.startsWith('/product/');
  }

  public logoutUser() {
    const r = this.userService.SetLogOut();
    if(r != null)
      r.subscribe();
    this.logout.emit(null);
  }
}
