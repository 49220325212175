
export class OrderItems {
  public orderItemId: number;

  public productId: number;

  public productCode: string;

  public quantity: number;

  public price: number;

  public lineNumber: number;

  public extendedPriceClient: number;

  public uom: string;

  public customerProductCode: string;

}
