import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService } from 'src/app/services/ProductService';
import { Product } from 'src/app/models/Product';
import { ResponseCode } from 'src/app/models/responses/ResponseCode';
import { InternalDataTransferService } from 'src/app/services/InternalDataTransferService';
import { DocumentService } from 'src/app/services/DocumentService';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from '../../models/Languages.enum';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../services/UserService';
import { Order } from 'src/app/models/Order';
import { OrderItems } from 'src/app/models/OrderItems';
import { OrderService } from '../../services/OrderService';
import { OrderSearch } from 'src/app/models/OrderSearch';

const isSafari =  navigator.vendor &&
                  navigator.vendor.indexOf('Apple') > -1 &&
                  navigator.userAgent &&
                  navigator.userAgent.indexOf('CriOS') === -1 &&
                  navigator.userAgent.indexOf('FxiOS') === -1;

const isEdge = navigator && navigator.msSaveOrOpenBlob;
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  public product: Product = null;
  public isLoadingError = false;
  keys = Object.keys;
  languages = Languages;
  selectedLanguage = 'English';
  loading = false;
  canPlaceOrder = false;
  userRegion = 0;
  orderShell: Order;
  private orderSearch: OrderSearch;
  orderItemId: number;
  itemIsInCart = false;
  cartBtnLbl = '';
  cartBtnFunction = 'A';
  disableCartBtn = false;
  public lotNum = '';

  constructor(
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private productService: ProductService,
    private internalDataTransferService: InternalDataTransferService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private userService: UserService,
    private orderService: OrderService
    ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id: string = params.id;
      this.productService.getProduct(id).subscribe(
        response => {
          if (response.status.code === ResponseCode.Ok && response.product !== null) {
            this.product = response.product;
            this.getUser();
          } else {
            this.product = null;
            this.isLoadingError = true;
          }
        },
        () => {
          this.product = null;
          this.isLoadingError = true;
        }
      );
    });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.onBackButtonClick(event);
  }
  setLanguage(language) {
    this.selectedLanguage = language;
  }

  onRunConditionsClick(productId: string, runConditionId: string) {
    this.loading = true;
    this.documentService.getApplicationDataSheetFile(productId, runConditionId, Languages[this.selectedLanguage]).subscribe(
      data => {
        this.downloadPdfFile(data, `${runConditionId}-ads`);
        this.loading = false;
      },
      () => {
        this.showNotification('ADS');
        this.loading = false;
      }
    );
  }

  onSdsClick(id: string, productId: string) {

    // const url = this.documentService.getSafetyDataSheetUrl(productId, Languages.English);
    // window.open(url);
    const process = this.documentService.getSafetyDataSheetUrlSDS(id, productId, Languages.English);
    this.loading = true;

    const env = this.documentService.getEnvUrl();
    // tslint:disable-next-line:max-line-length
    let url = `https://${env}buyat.ppg.com/EHSDocumentManagerPublic/documentSearch.aspx?FolderID1=87&SearchAllPublicFolders=True&CodeCondition=BeginsWith&CodeValue=${productId}&SuppressSearchControls=True`;

    process.subscribe(
      result => {
        if (result)  {
    // tslint:disable-next-line:max-line-length
          url = `https://${env}buyat.ppg.com/EHSDocumentManagerPublic/documentSearch.aspx?FolderID1=87&SearchAllPublicFolders=True&NameCondition=BeginsWith&NameValue=${productId}&SuppressSearchControls=True`;
        }
      },
      m => {
        console.log(' Failed load ');
      },
      () => {
        this.loading = false;
        window.open(url);
      }
      );
  }
  onTdsClick(productId: string) {
    this.loading = true;
    this.documentService.getProductDataSheetFile(productId, Languages[this.selectedLanguage]).subscribe(
      data => {
        this.downloadPdfFile(data, `${productId}-pds`);
        this.loading = false;
      },
      () => {
        this.showNotification('PDS');
        this.loading = false;
      }
    );
  }

  onVocClick(productId: string) {
    this.loading = true;
    this.documentService.getVocFile(productId).subscribe(
      data => {
        this.downloadPdfFile(data, `${productId}-voc`);
        this.loading = false;
      },
      () => {
        this.showNotification('VOC');
        this.loading = false;
      }
    );
  }

  onDownloadClick(parentItem: string, lotNumber: string) {
    this.loading = true;
    this.lotNum = lotNumber;
    this.documentService.getCoaFile(parentItem, lotNumber).subscribe(
      data => {
        this.downloadPdfFile(data, `${lotNumber}-coa`);
        this.loading = false;
      },
      () => {
        this.showNotification('COA');
        this.loading = false;
      }
    );
  }


  private onBackButtonClick(event) {
    if (event.target.location.href && event.target.location.href.indexOf('/products') >= 0) {
      this.internalDataTransferService.setStorage({
        back: true
      });
    }
  }

  private downloadPdfFile(data: Blob, name?: string) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const blobUrl = window.URL.createObjectURL(blob);
    if (isEdge) {
      window.navigator.msSaveOrOpenBlob(blob, `${name}.pdf`);
    } else
    if (isSafari) {
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${name}.pdf`;
      link.click();
    } else {
      window.open(blobUrl);
    }
  }

  private showNotification(fileType: string) {
    this.toastr.error(`${this.translate.instant('OTHER.ERROR_DOWNLOADING', {value: fileType})}`);
  }

  private getUser() {
    this.userService.getUser().subscribe(
      data => {
        if (data) {
          this.canPlaceOrder = data.user.canPlaceOrders;
          this.userRegion = data.user.userRegion;
          if (this.canPlaceOrder) {
            this.orderSearch = new OrderSearch();
            this.orderShell = new Order();
            this.orderSearch.orderStatus = 'CART_CREATED';
            this.getOrder();  // find existing cart
          } else {
            this.loading = false;
          }
          this.isLoadingError = false;
        }
      },
      () => {
        this.isLoadingError = true;
      }
    );
  }

  private setCartButtonLbl() {
    if (this.itemIsInCart) {
      this.cartBtnLbl = this.translate.instant('ORDER.REMOVE_CART');
      this.cartBtnFunction = 'R';
    } else {
      this.cartBtnLbl = this.translate.instant('ORDER.ADD_TO_CART');
      this.cartBtnFunction = 'A';
    }
  }

  cartClick(product: Product, fct: string) {
    if (product.isObsolete) {
      return;
    }

    this.disableCartBtn = true;
    if (fct === 'A') {
      const oi = new OrderItems();
      oi.productId = product.localId;
      oi.quantity = 1;
      oi.productCode = product.name;

      if (!this.orderShell.orderId || this.orderShell.orderId === '') {
        this.orderShell.orderId = '00000000-0000-0000-0000-000000000000';
        this.orderShell.orderStatus = 'CART_CREATED';
      }
      this.orderShell.orderItems = new Array<OrderItems>();
      this.orderShell.orderItems.push(oi);

      this.orderService.UpsertOrder(this.orderShell, 'ADD_ITEM').subscribe(
        data => {
          if (data.status.code === ResponseCode.Ok) {
            // we do not receive full order details after upsert. Do not replace order here.
            this.orderShell.orderId = data.order.orderId;
            this.itemIsInCart = true;
            this.orderItemId = data.order.orderItems[0].orderItemId;
            this.setCartButtonLbl();
            this.disableCartBtn = false;
          }
        },
        err => {
        },
        () => {
        }
      );
    } else {
      this.orderShell.orderItems = [];
      this.orderShell.orderItems[0] = new OrderItems();
      this.orderShell.orderItems[0].orderItemId = this.orderItemId;
      this.orderService.UpsertOrder(this.orderShell, 'DELETE_ITEM').subscribe(
        data => {
          if (data.status.code === ResponseCode.Ok) {
            this.itemIsInCart = false;
            this.orderItemId = -1;
            this.setCartButtonLbl();
            this.disableCartBtn = false;
          }
        },
        err => {
        },
        () => {
        }
      );
    }
  }

  private getOrder() {
    this.loading = true;
    this.orderService.GetOrder(this.orderSearch).subscribe(
      data => {
        if (data.status.code === ResponseCode.Ok) {
          this.orderShell = new Order();
          if (data.order && data.order.length > 0) {
            this.orderShell = data.order[0];
            this.setProductOrderItemId();
            if (this.orderItemId > 0) {
              this.itemIsInCart = true;
            }
          }
        } else {
          this.orderShell = new Order();
        }
        this.setCartButtonLbl();
        this.loading = false;
      },
      err => {
      },
      () => {
      }
    );
  }

  private setProductOrderItemId() {
    if (this.orderShell.orderItems.length > 0) {
      const oi = this.orderShell.orderItems.find(i => i.productCode === this.product.name);

      if (this.product.isObsolete) {
        this.orderItemId = 0;
      } else {
        this.orderItemId = oi != null ? oi.orderItemId : 0;
      }
    }
  }
}
