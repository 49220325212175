import { Component, OnInit, Inject } from '@angular/core';
import { Order } from '../../models/Order';
import { ResponseCode } from '../../models/responses/ResponseCode';
import { OrderService } from 'src/app/services/OrderService';
import { OrderItems } from 'src/app/models/OrderItems';
import { OrderSearch } from 'src/app/models/OrderSearch';
import { Form, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderAddress } from 'src/app/models/OrderAddress';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})

export class CheckoutComponent implements OnInit {
  public loading = true;
  public order: Order = new Order();
  public orderSearch: OrderSearch = new OrderSearch();
  public componentOrderItems: OrderItems[] = [];
  public errorTxtMsg: string;
  public total: number;
  public orderCustInfo = '';
  public orderNumber: number;
  public isReadOnly = false;
  public uomOptions: string[] = ['DR', 'PL', 'TW'];
  public hidePrice = true;
  public submitDisabled = false;

  displayedColumns: string[] = ['modifyRowCol', 'lineNumberCol', 'productCol', 'quantityCol', 'uomCol'];
  footerDisplayedColumns: string[] = [];

  constructor(
    private orderService: OrderService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.orderNumber = +params.get('id');
    });

    if (this.orderNumber > 0) {
      this.isReadOnly = true;
      this.orderSearch.orderNumber = this.orderNumber;
      this.orderSearch.orderStatus = 'PLACED';
    } else {
      this.isReadOnly = false;
      this.orderSearch.orderStatus = 'CART_CREATED';
    }

    this.getOrder();
  }

  removeItemClick(orderItem: OrderItems) {
    if (confirm('Do you wish to remove this item?')) {
      this.RemoveOrderItem(orderItem);
    }
  }

  copyOrderClick() {
    if (confirm('Do you wish to copy this order to your cart?')) {
      this.copyOrder();
    }
  }

  submitOrderClick() {
    this.UpsertOrder(true);
  }

  private setShipToTextArea() {
    if (this.order.address) {
      this.orderCustInfo = (this.order.address.addressLine1 != null ? `${this.order.address.addressLine1}\r\n` : '')
        + (this.order.address.addressLine2 != null ? `${this.order.address.addressLine2}\r\n` : '')
        + (this.order.address.city != null ? `${this.order.address.city}, ` : '')
        + (this.order.address.state != null ? `${this.order.address.state} ` : '')
        + (this.order.address.postalCode != null ? `${this.order.address.postalCode}\r\n` : '')
        + (this.order.address.country != null ? `${this.order.address.country}\r\n` : '');
      if (this.orderCustInfo.length > 10) {
        this.orderCustInfo = `${this.order.customerName} \r\n` + this.orderCustInfo;
      } else {
        this.orderCustInfo = '';
      }
    } else {
      this.orderCustInfo = '';
    }
  }

  private getOrder() {
    this.orderService.GetOrder(this.orderSearch).subscribe(
      data => {
        if (data.status.code === ResponseCode.Ok) {
          this.order = new Order();

          if (data.order && (data.order.length > 0 && data.order[0].orderItems.length > 0)) {
            this.order = data.order[0];
            this.ProcessOrderFields();
          } else {
            this.order.orderItems = [];
          }
        } else {
          this.order = new Order();
        }
      },
      err => {

      },
      () => {
        this.loading = false;
      }
    );
  }

  private copyOrder() {
    this.submitDisabled = true;
    this.loading = true;
    this.orderService.CopyOrder(this.order).subscribe(
      data => {
        if (data.status.code === ResponseCode.Ok) {
          this.order = new Order();

          if (data.order) {
            this.order = data.order;
            this.ProcessOrderFields();
            this.isReadOnly = false;
          } else {
            this.order.orderItems = [];
          }
        } else {
          this.order = new Order();
        }
      },
      err => {

      },
      () => {
        this.loading = false;
        this.submitDisabled = false;
      }
    );
  }

  private UpsertOrder(submitOrder: boolean) {
    let d: Date;

    if (!this.order.requiredDate) {
      d = new Date();
      this.order.requiredDate = d.toDateString();
    }

    this.orderService.UpsertOrder(this.order, 'FULL_UPDATE').subscribe(
      data => {
        if (data.status.code === ResponseCode.Ok) {
          if (submitOrder) {
            this.SubmitOrder();
          }
        }
      },
      err => {
      },
      () => {
      }
    );
  }

  private SubmitOrder() {
    this.submitDisabled = true;
    this.orderService.SubmitOrder(this.order.orderId).subscribe(
      data => {
        if (data && data.status.code === ResponseCode.Ok && data.result) {
          this.router.navigate(['/orders/search']);
        } else {
          alert('Order submission failed!');
        }
      },
      err => {
        alert('Order submission failed!');
      },
      () => {
        this.loading = false;
        this.submitDisabled = false;
      }
    );
  }

  private RemoveOrderItem(orderItem: OrderItems) {
    const orderShell: Order = new Order();
    orderShell.orderId = this.order.orderId;
    orderShell.orderItems[0] = new OrderItems();
    orderShell.orderItems[0].orderItemId = orderItem.orderItemId;
    this.orderService.UpsertOrder(orderShell, 'DELETE_ITEM').subscribe(
      data => {
        if (data.status.code === ResponseCode.Ok) {
          this.getOrder();
        }
      },
      err => {
      },
      () => {
      }
    );
  }

  public GetExtendedPriceClient(oi: OrderItems, qty: number) {
    oi.extendedPriceClient = this.DecimalFormatted((this.DecimalFormatted(qty, 2) * this.DecimalFormatted(oi.price, 2)), 2);
    this.GetTotal();
  }

  private ProcessOrderFields() {
    let counter = 0;

    this.order.orderItems.forEach(orderItem => {
      orderItem.extendedPriceClient = this.DecimalFormatted(
        this.DecimalFormatted(orderItem.quantity, 2) * this.DecimalFormatted(orderItem.price, 2)
        , 2);
      counter = counter + 1;
      orderItem.lineNumber = counter;
    });

    this.GetTotal();

    if (!this.hidePrice) {
      this.displayedColumns.push('priceCol', 'extendedPriceCol');
      this.footerDisplayedColumns.push('priceCol', 'extendedPriceCol');
    }

    this.setShipToTextArea();
  }

  GetTotal() {
    this.total = this.order.orderItems.reduce((tempTotal: number, oi: OrderItems) => {
      return tempTotal + oi.extendedPriceClient;
    }, 0);
  }

  NumberToLocale(num: number, minFractionDigits: number, maxFractionDigits: number): string {
    const tempNum: number = isNaN(num) ? 0 : num;
    return tempNum.toLocaleString('en-US', { maximumFractionDigits: maxFractionDigits, minimumFractionDigits: minFractionDigits });
  }

  DecimalFormatted(num: number, decimalPlace: number): number {
    return +((+num).toFixed(decimalPlace));
  }

  GetLocaleDate(d: string) {
    if (d) {
      const date = new Date(d);
      return date.toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    } else {
      return '';
    }

  }

  MoveToEndOnFocus(elm: any) {
    elm.type = 'text';
    elm.setSelectionRange(elm.value.length, elm.value.length);
    elm.type = 'number';
  }
}


