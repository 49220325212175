import { Injectable } from '../../../node_modules/@angular/core';
import { ServiceBase } from './ServiceBase';
import { Observable } from '../../../node_modules/rxjs';
import { HttpHeaders, HttpParams } from '../../../node_modules/@angular/common/http';
import { OrderResponse } from '../models/responses/OrderResponse';
import { CopyOrderResponse } from '../models/responses/CopyOrderResponse';
import { UpsertOrderResponse } from '../models/responses/UpsertOrderResponse';
import { SubmitOrderResponse } from '../models/responses/SubmitOrderResponse';
import { GetOrderAddressResponse } from '../models/responses/GetOrderAddressResponse';
import { Order } from '../models/Order';
import { OrderSearch } from '../models/OrderSearch';

@Injectable()
export class OrderService {
  constructor(private serviceBase: ServiceBase) { }

  UpsertOrder(orderReq: Order, cartFunc: string): Observable<UpsertOrderResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.serviceBase.postWithOptions(`/order/UpsertOrder?cartFunc=${cartFunc}`, JSON.stringify(orderReq), headers, null);
  }

  CopyOrder(orderReq: Order): Observable<CopyOrderResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.serviceBase.postWithOptions('/order/CopyOrder', JSON.stringify(orderReq), headers, null);
  }

  SubmitOrder(orderId: string): Observable<SubmitOrderResponse> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');

    return this.serviceBase.postWithOptions(`/order/SubmitOrder/${orderId}`, null, headers, null);
  }

  GetOrder(orderSearch: OrderSearch): Observable<OrderResponse>  {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    return this.serviceBase.postWithOptions('/order/GetOrder', JSON.stringify(orderSearch), headers, null);
  }

}
