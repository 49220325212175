export class OrderSearch {
  public userId: number;

  public customerId: number;

  public orderId: string;

  public orderStatus: string;

  public orderCustomerId: number;

  public orderUserId: number;

  public orderNumber: number;
}
