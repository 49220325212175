import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ScanningComponent } from './components/scanning/scanning.component';
import { ScanningTestComponent } from './components/scanning-test/scanning.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { ErrorComponent } from './components/error/error.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthorizationGuard } from './guards/AuthorizationGuard';
import { RedirectComponent } from './components/redirect/redirect.component';
import { NoProductComponent } from './components/no-product/no-product.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { OrderSearchComponent } from './components/order-search/order-search.component';
import { PassResetComponent } from './components/pass-reset/pass-reset.component';

const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent },
  { path: 'resetpassword', component: PassResetComponent},
  { path: 'redirect', component: RedirectComponent },
  { path: 'error/:type', component: ErrorComponent },
  { path: 'products', component: ProductListComponent, canActivate: [AuthorizationGuard] },
  { path: 'product/:id', component: ProductDetailsComponent, canActivate: [AuthorizationGuard] },
  { path: 'scan', component: ScanningComponent, canActivate: [AuthorizationGuard] },
  { path: 'scan_test', component: ScanningTestComponent, canActivate: [AuthorizationGuard] },
  { path: 'no-product', component: NoProductComponent, canActivate: [AuthorizationGuard] },
  { path: 'orders', redirectTo: 'error/notfound' },
  {
    path: 'orders',
    data: {
      role: 'order'
    },
    children: [
      { path: 'checkout/:id', component: CheckoutComponent, canActivate: [AuthorizationGuard] },
      { path: 'checkout', component: CheckoutComponent, canActivate: [AuthorizationGuard]},
      { path: 'search', component: OrderSearchComponent, canActivate: [AuthorizationGuard]},
    ]
  },
  { path: '**', redirectTo: '/sign-in' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
