import { Injectable } from '../../../node_modules/@angular/core';
import { ServiceBase } from './ServiceBase';
import { UserResponse } from '../models/responses/UserResponse';
import { ResponseCode } from '../models/responses/ResponseCode';
import { User } from '../models/User';
import { Observable, of } from '../../../node_modules/rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class UserService {
    constructor(private serviceBase: ServiceBase) { }

  getUser(): Observable<UserResponse> {
    const user = sessionStorage.getItem('user');

    if (user) {
      console.log(user);
      return of(JSON.parse(user));
    } else {
      return this.serviceBase.get<UserResponse>('/user').pipe(
        tap((u: UserResponse) => {
          if (u && u.user && u.user.customer) {
            u.user.canPlaceOrders = u.user.canPlaceOrders && (u.user.customer.canPlaceOrder ? u.user.customer.canPlaceOrder : false);
          } else {
            u.user.canPlaceOrders = false;
          }
          sessionStorage.setItem('user', JSON.stringify(u));
        })
      );
    }
  }
  SetLogOut(): Observable<UserResponse> {
    const session = sessionStorage.getItem('user');
    if (session == null) return null;
    const object = JSON.parse(session);
    return this.serviceBase.get<UserResponse>(`/event/SetLogOutDate/${object.user.emailAddress}`);
  }
}
