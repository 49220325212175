import { OidcConfigService, OidcSecurityService, OpenIDImplicitFlowConfiguration, AuthWellKnownEndpoints } from 'angular-auth-oidc-client';
import { Injectable } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { EnvironmentService } from './EnvironmentService';
import { AuthConfig } from 'src/environments/AuthorizationSetting';
import { Environment } from 'src/environments/environment';

export enum AuthType {
    ADFS = 'ADFS',
    ADB2C = 'ADB2C'
}

@Injectable()
export class AuthService {
    private readonly authTypeStorageItemName: string = 'auth-type';
    private readonly adb2cResponseFragmentFirstArgumentName: string = 'state';
    private readonly adfsResponseFragmentFirstArgumentName: string = 'access_token';

    private isLoggedInSubscriptions: Subscription[] = [];

    constructor(
        private oidcConfigService: OidcConfigService,
        private oidcSecurityService: OidcSecurityService,
        private environmentService: EnvironmentService) { }

    authorizationCallback() {
        const type: AuthType = this.getType();
        const responseFragmentFirstArgumentName = this.getResponseFragmentFirstArgumentName(type);
        const hash = this.getTokenHash(responseFragmentFirstArgumentName);

        if (hash) {
            this.oidcSecurityService.authorizedImplicitFlowCallback(hash);
        }
    }

    configure(type?: AuthType) {
        if (!type) {
            type = this.getType();
        }

        if (type) {
            this.configure_internal(type);
        } else {
            // window.location.href = this.environmentService.getEnvironment().authorizationConfig.common.postLogoutRedirectUrl;
            this.configure_internal(AuthType.ADB2C);
        }
    }

    getIsLoggedIn(): Observable<boolean> {
        return this.oidcSecurityService.getIsAuthorized();
    }

    signIn() {
        this.oidcSecurityService.getIsModuleSetup().pipe(
            filter((isModuleSetup: boolean) => isModuleSetup),
            take(1)
        ).subscribe(() => {
            const config = this.getConfig(this.getType());

            if (config.resourceId) {
                this.oidcSecurityService.setCustomRequestParameters({
                    resource: config.resourceId
                });
            }

            this.oidcSecurityService.authorize();
        });
    }

    signOut() {
        this.clear();
        this.oidcSecurityService.logoff();
    }

    resetPassword() {
        const config: AuthConfig = this.getConfig(AuthType.ADB2C);

        window.location.href = `${config.passResetUrl}?p=${config.passResetPolicy}&client_id=${config.clientId}
                                &nonce=defaultNonce&redirect_uri=${window.location.origin}
                                &scope=${config.passResetScope}&response_type=${config.passResetResponseType}&prompt=login`;
    }

    private clear() {
        if (this.isLoggedInSubscriptions) {
            this.isLoggedInSubscriptions.forEach(subscription => {
                subscription.unsubscribe();
            });
        }

        this.isLoggedInSubscriptions = [];
        localStorage.removeItem(this.authTypeStorageItemName);
        sessionStorage.removeItem('user');
    }

    private configure_internal(type: AuthType) {
        this.setType(type);
        const config: AuthConfig = this.getConfig(type);

        this.oidcConfigService.load_using_custom_stsServer(config.metadataUrl);
        this.oidcConfigService.onConfigurationLoaded.subscribe(() => {
            const environment: Environment = this.environmentService.getEnvironment();
            const openIDImplicitFlowConfiguration = new OpenIDImplicitFlowConfiguration();
            openIDImplicitFlowConfiguration.stsServer = config.stsServerUrl;
            openIDImplicitFlowConfiguration.client_id = config.clientId;
            openIDImplicitFlowConfiguration.scope = config.scope;
            openIDImplicitFlowConfiguration.redirect_url = environment.authorizationConfig.common.redirectUrl;
            openIDImplicitFlowConfiguration.response_type = environment.authorizationConfig.common.responseType;
            openIDImplicitFlowConfiguration.post_logout_redirect_uri = environment.authorizationConfig.common.postLogoutRedirectUrl;
            openIDImplicitFlowConfiguration.post_login_route = environment.authorizationConfig.common.postLoginRoute;
            openIDImplicitFlowConfiguration.forbidden_route = environment.authorizationConfig.common.forbiddenRoute;
            openIDImplicitFlowConfiguration.unauthorized_route = environment.authorizationConfig.common.unauthorizedRoute;
            openIDImplicitFlowConfiguration.auto_userinfo = environment.authorizationConfig.common.autoUserInfo;
            openIDImplicitFlowConfiguration.log_console_warning_active = environment.authorizationConfig.common.enableLogs;
            openIDImplicitFlowConfiguration.log_console_debug_active = environment.authorizationConfig.common.enableLogs;
            openIDImplicitFlowConfiguration.max_id_token_iat_offset_allowed_in_seconds =
                environment.authorizationConfig.common.maxIdTokenIATOffsetAllowedInSeconds;
            openIDImplicitFlowConfiguration.storage = environment.authorizationConfig.common.storage;
            openIDImplicitFlowConfiguration.silent_renew = environment.authorizationConfig.common.renewToken;
            openIDImplicitFlowConfiguration.silent_renew_url = environment.authorizationConfig.common.renewTokenUrl;
            openIDImplicitFlowConfiguration.silent_renew_offset_in_seconds = config.renewTokenSecondsOffset;

            const authWellKnownEndpoints = new AuthWellKnownEndpoints();
            authWellKnownEndpoints.setWellKnownEndpoints(this.oidcConfigService.wellKnownEndpoints);

            this.oidcSecurityService.setupModule(openIDImplicitFlowConfiguration, authWellKnownEndpoints);
        });
    }

    private getConfig(type: AuthType) {
        const environment: Environment = this.environmentService.getEnvironment();
        switch (type) {
            case AuthType.ADB2C:
            case AuthType.ADFS:
                return environment.authorizationConfig.ADB2C;
           
               // return environment.authorizationConfig.ADFS;
            default:
                // return undefined;
                return environment.authorizationConfig.ADB2C;
        }
    }

    private getResponseFragmentFirstArgumentName(type: AuthType) {
        switch (type) {
            case AuthType.ADB2C:
            case AuthType.ADFS:
                return this.adb2cResponseFragmentFirstArgumentName;
            
               // return this.adfsResponseFragmentFirstArgumentName;
            default:
                return undefined;
        }
    }

    private getTokenHash(fragmentFirstParameterName: string) {
        if (typeof location !== undefined && window.location.hash) {
            const indexHash = window.location.hash.indexOf(fragmentFirstParameterName);
            return indexHash > -1 && window.location.hash.substr(indexHash);
        }
    }

    private getType() {
        const type = localStorage.getItem(this.authTypeStorageItemName);

        switch (type) {
            case AuthType.ADB2C.toString():
            case AuthType.ADFS.toString():
                return AuthType.ADB2C;
            
                //return AuthType.ADFS;
            default:
                return undefined;
        }
    }

    private setType(type: AuthType) {
        localStorage.setItem(this.authTypeStorageItemName, type.toString());
    }
}
