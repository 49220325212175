import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-action-dropdown-list',
  templateUrl: './action-dropdown-list.component.html',
  styleUrls: ['./action-dropdown-list.component.scss']
})
export class ActionDropdownListComponent implements OnInit {
  @Input() title: string;
  @Input() icon = '';
  @Input() buttonClass = 'btn-primary';
  @Input() mobileDropdown = 0;
  @Input() items: ActionDropdownItem[] = [];
  @Input() itemsRunConditions: ActionDropdownItem[] = [];

  constructor( private translate: TranslateService ) { }

  ngOnInit(): void {
    for (const item of this.itemsRunConditions) {
      item.name = item.name.replace('- Run Condition', '');
      item.name = item.name.replace(' Run Condition', '');
    }
  }

  public onClick(item: ActionDropdownItem) {
    if (item) {
      item.action();
    }
  }
}

export interface ActionDropdownItem {
  name: string;
  class?: string;
  action(): void;
}
