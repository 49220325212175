import { Customer } from './Customer';

export class User  {

  public emailAddress: string;

  public firstName: string;

  public lastName: string;

  public companyId: number;

  public customer: Customer;

  public isInternal: boolean;

  public canPlaceOrders: boolean;

  public userRegion: number;

}
