import { Component, OnInit, ViewChild } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { ProductService } from 'src/app/services/ProductService';
import { ResponseCode } from 'src/app/models/responses/ResponseCode';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { BarcodeFormat } from '@zxing/library';
import { StringMapWithRename } from '@angular/core/src/render3/jit/compiler_facade_interface';

declare var Quagga: any;

@Component({
  selector: 'app-scanning-test',
  templateUrl: './scanning.component.html',
  styleUrls: ['./scanning.component.scss']
})

export class ScanningTestComponent implements OnInit {
  @ViewChild('scanner')
  private scanner: ZXingScannerComponent;
  private hasCameras = false;
  private hasPermission: boolean;

  public isScannerVisible = false;
  public selectedDevice: MediaDeviceInfo;
  public scannedCode: string = null;
  public scannedCodeQuagga: string = null;
  public showNoProductFoundError = false;
  public allowedFormats = [BarcodeFormat.QR_CODE, BarcodeFormat.CODE_128, BarcodeFormat.CODE_39];
  public checkScanner = true;
  public scannerDebugInfo = '';


  constructor(
    private productService: ProductService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService
    ) {
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (event.url === '/no-product') {
            this.router.navigate(['scan']);
          }
        }
      });
    }

  ngOnInit(): void {
    Quagga.stop();
    //  this.initializeScanner();
    this.initQuaggaScanner();
  }
  ngOnChange(): void {
    Quagga.stop();
  }
  clearDataScannerLog() {
    this.scannerDebugInfo = '';
    this.checkScanner = true;
    this.initQuaggaScanner();
  }

  addDataScannerLog(data: string) {
    if (this.scannerDebugInfo) {
      data = '<br>' + data;
    }
    this.scannerDebugInfo += data;
  }

  initializeScanner() {
    this.addDataScannerLog(this.translate.instant('SCANNING.SCANNER_INITILIZE'));
    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.hasCameras = true;

      let deviceToBeSelected: MediaDeviceInfo = null;
      this.addDataScannerLog(this.translate.instant('SCANNING.ACCESING_CAMERA') + '...');
      // try to find back camera
      for (const device of devices) {
        if (/back|rear|environment/gi.test(device.label)) {
          deviceToBeSelected = device;
          this.addDataScannerLog('Back camera found.');
          break;
        }
      }

      // if back camera is not found take first camera
      if (!deviceToBeSelected) {
        this.addDataScannerLog(this.translate.instant('SCANNING.BACK_CAMERA_NOT_FOUND') + '...');
        for (const device of devices) {
          deviceToBeSelected = device;
          break;
        }
      }

      this.scanner.changeDevice(deviceToBeSelected);
      this.selectedDevice = deviceToBeSelected;
      this.openScanning();
    });

    this.scanner.permissionResponse.subscribe((answer: boolean) => {
      this.hasPermission = answer;
    });
  }
  initQuaggaScanner() {
    this.addDataScannerLog(this.translate.instant('SCANNING.SCANNER_INITILIZE'));
    this.addDataScannerLog(this.translate.instant('SCANNING.ACCESING_CAMERA') + '...');
    const that = this;
    Quagga.init({
      inputStream : {
        name : 'Live',
        type : 'LiveStream',
        target: document.querySelector('#interactive')    // Or '#yourElement' (optional)
      },
      decoder : {
        readers : ['ean_reader', 'ean_5_reader', 'ean_2_reader', 'code_128_reader', 'code_39_reader']
      }
    }, function(err) {
        if (err) {
            console.log(err);
            this.addDataScannerLog(this.translate.instant('SCANNING.BACK_CAMERA_NOT_FOUND') + '...');
            return;
        }
        // console.log("Initialization finished. Ready to start");
        Quagga.start();
        Quagga.onDetected((res) => {
            //  window.alert(`code: ${res.codeResult.code}`);
             if (res.codeResult.code && that.checkScanner) {
              Quagga.stop();
              that.getScannedCode(res.codeResult.code);
             }
        });
    });
  }
  handleScannedCode(code: string) {
    // Remove prefix (labelling convention):
    this.addDataScannerLog('Code scanned: ' + code);
    if (code.startsWith('P')) {
      this.addDataScannerLog('First letter removed: P');
    }
    if (code.startsWith('T')) {
      this.addDataScannerLog('First letter removed: T');
    }
    if (code.startsWith('S')) {
      this.addDataScannerLog('First letter removed: S');
    }

    this.scannedCode = (code.startsWith('P') || code.startsWith('T') || code.startsWith('S'))
      ? code.substring(1)
      : code;

    // Remove text after the last / in the product code
    if (this.scannedCode.indexOf('/') > 0) {
      this.addDataScannerLog('Special char found \'/\' Removed: ' +
      this.scannedCode.substring(this.scannedCode.lastIndexOf('/'), this.scannedCode.length));
      this.scannedCode = this.scannedCode.substring(0, this.scannedCode.lastIndexOf('/'));
    }

    this.isScannerVisible = false;
    this.scanner.resetCodeReader();
    this.scanner.scannerEnabled = false;

    this.addDataScannerLog('Code to search: ' + this.scannedCode);
    this.productService.getProduct(this.scannedCode).subscribe(
      response => {
        if (response.status.code === ResponseCode.Ok && response.product.id !== null) {
          this.toastr.success(`${this.translate.instant('SCANNING.SCAN_OK')}: ${this.scannedCode}`);
          this.addDataScannerLog('Product found: ' + this.scannedCode);
          // this.router.navigate(['/product', this.scannedCode]);
        } else {
          this.toastr.error(`${this.translate.instant('SCANNING.NO_PRODUCT')}`);
          this.addDataScannerLog('Product Not Found: ' + this.scannedCode);
          if (response.status.code !== ResponseCode.Ok) {
            this.addDataScannerLog('Response from server is not Ok. Status Code: ' + response.status.code);
          }
          // this.router.navigate(['/no-product']);
        }
        this.isScannerVisible = true;
        this.scanner.scannerEnabled = true;
        this.scanner.restartScan();
      },
      (err) => {
        this.toastr.error(`${this.translate.instant('SCANNING.NO_PRODUCT')}`);
        this.addDataScannerLog('Product Not Found: ' + this.scannedCode);
        this.addDataScannerLog('Error: ' + err.message);
        this.isScannerVisible = true;
        this.scanner.scannerEnabled = true;
        this.scanner.restartScan();
        // this.router.navigate(['/no-product']);
      }
    );
  }
  getScannedCode(code: string) {
    this.checkScanner = false;
    this.scannedCode = (code.startsWith('P') || code.startsWith('T') || code.startsWith('S'))
      ? code.substring(1)
      : code;

    // Remove text after the last / in the product code
    if (this.scannedCode.indexOf('/') > 0) {
      this.scannedCode = this.scannedCode.substring(0, this.scannedCode.lastIndexOf('/'));
    }

    this.addDataScannerLog('Code to search: ' + this.scannedCode);

    this.productService.getProduct(this.scannedCode).subscribe(
      response => {
        if (response.status.code === ResponseCode.Ok && response.product.id !== null) {
          this.toastr.success(`${this.translate.instant('SCANNING.SCAN_OK')}: ${this.scannedCode}`);
          this.addDataScannerLog('Product found: ' + this.scannedCode);
        } else {
          this.toastr.error(`${this.translate.instant('SCANNING.NO_PRODUCT')}`);
          this.addDataScannerLog('Product Not Found: ' + this.scannedCode);
          if (response.status.code !== ResponseCode.Ok) {
            this.addDataScannerLog('Response from server is not Ok. Status Code: ' + response.status.code);
          }
        }
      },
      (err) => {
        this.toastr.error(`${this.translate.instant('SCANNING.NO_PRODUCT')}`);
        this.addDataScannerLog('Product Not Found: ' + this.scannedCode);
        this.addDataScannerLog('Error: ' + err.message);
      }
    );
  }

  onDeviceSelectChange(selectedValue: string) {
    this.selectedDevice = this.scanner.getDeviceById(selectedValue);
  }

  openScanning() {
    this.showNoProductFoundError = false;
    if (this.hasCameras && this.hasPermission && this.selectedDevice != null) {
      this.isScannerVisible = true;
      this.scanner.scannerEnabled = true;
      this.scanner.restartScan();
    }
  }
}

