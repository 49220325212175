import { Injectable } from '@angular/core';
import { Environment } from 'src/environments/environment';
import { local, dev, test, prd } from 'src/environments/EnvironmentDefinitions';

@Injectable()
export class EnvironmentService {
    constructor() {
        const hostname: string = window.location.hostname.toLowerCase().trim();
        this.environment = EnvironmentService.urlToEnvironment[hostname];
    }

    private static urlToEnvironment: {[key: string]: Environment} = {
        localhost: local,
        'devpackagingtdsspa.z20.web.core.windows.net': dev,
        'testpackagingtdsspa.z20.web.core.windows.net': test,
        'packagingtdsspa.z20.web.core.windows.net': prd,
        'packaging.ppg.com': prd,
    };

    private environment: Environment;

    getEnvironment(): Environment {
        return this.environment;
    }
}
