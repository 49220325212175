import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthorizationState } from '../../models/AuthorizationState';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnDestroy, OnInit {
  private subscription: Subscription;
  public message: string;
  public header: string;
  public type: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService) {
      this.subscription = this.route.params.subscribe(params => {
        this.type = params.type;

        if (this.type) {
          this.type = this.type.toLowerCase();
        }
        this.showForbiddenMessage(this.type);
      });
    }

  ngOnInit() {
    console.log(this.type);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private showForbiddenMessage(type: string) {
    if (type === 'forbidden') {
      const state: AuthorizationState = this.router.getCurrentNavigation().extras.state.error.state;
      switch (state) {
        case AuthorizationState.Disabled:
          this.header = this.translate.instant('OTHER.ERROR_ACCESS_DENIED');
          this.message = this.translate.instant('OTHER.ERROR_FORBIDDEN_DISABLED');
          break;
        case AuthorizationState.Unverified:
          this.header = this.translate.instant('OTHER.PENDING_AUTHORIZATION');
          this.message = this.translate.instant('OTHER.ERROR_FORBIDDEN_UNVERIFIED');
          break;
      }
    }
  }
}
