import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/User';

import { Languages } from 'src/app/models/Languages.enum';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  @Input()
  public user: User;

  keys = Object.keys;
  languages = Languages;
  selectedLanguage = 'en-us';
  selectedLanguage2 = 'en-us';

  constructor(
    private translate: TranslateService,
    ) {}

  get UserName(): string {
    if (this.user) {
      return this.user.firstName + ' ' + this.user.lastName;
    } else {
      return 'Not Signed-In';
    }

  }

  ngOnInit() {
    if ( localStorage.getItem('siteLang')) {
      this.selectedLanguage = localStorage.getItem('siteLang');
    }
    if ( localStorage.getItem('fileLang')) {
      this.selectedLanguage2 = localStorage.getItem('fileLang');
    }
  }

  get CustomerName(): string {
    if (this.user && this.user.customer) {
      return this.user.customer.customerName;
    } else if (this.user.emailAddress.includes('ppg.com')) {
      return 'PPG Employee';
    } else {
      return 'Customer not assigned';
    }

  }

  setLanguage(language) {
    localStorage.setItem('fileLang', language);
  }

  setSiteLanguage(languageStr) {
    this.selectedLanguage = languageStr;
    this.translate.use(languageStr);
    localStorage.setItem('siteLang', languageStr);
  }

}
