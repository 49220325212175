import { Injectable } from '../../../node_modules/@angular/core';
import { ServiceBase } from './ServiceBase';
import { Observable } from '../../../node_modules/rxjs';
import { HttpParams } from '../../../node_modules/@angular/common/http';
import { GetProductListItemsResponse } from '../models/responses/GetProductListItemsResponse';
import { GetProductResponse } from '../models/responses/GetProductResponse';

@Injectable()
export class ProductService {
    constructor(private serviceBase: ServiceBase) { }

    getProductList(idOrDescription: string): Observable<GetProductListItemsResponse> {
        let params: HttpParams = new HttpParams();
        params = params.append('idOrDescription', idOrDescription);
        return this.serviceBase.get<GetProductListItemsResponse>(`/product`, params);
    }

    getProduct(id: string): Observable<GetProductResponse> {
        return this.serviceBase.get<GetProductResponse>(`/product/${id}`);
    }
}
