import { Component, OnInit, HostListener, ViewChild, Input, AfterViewInit } from '@angular/core';
import { ProductService } from '../../services/ProductService';
import { ProductListItem } from '../../models/ProductListItem';
import { ResponseCode } from '../../models/responses/ResponseCode';
import { SearchFieldComponent } from '../search-field/search-field.component';
import { ActionDropdownItem } from '../action-dropdown-list/action-dropdown-list.component';
import { Router } from '@angular/router';
import { InternalDataTransferService } from 'src/app/services/InternalDataTransferService';
import { DocumentService } from 'src/app/services/DocumentService';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Languages } from 'src/app/models/Languages.enum';
import { Order } from '../../models/Order';
import { OrderItems } from '../../models/OrderItems';
import { OrderService } from 'src/app/services/OrderService';
import { OrderSearch } from 'src/app/models/OrderSearch';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/UserService';
import {​​​​​MatTableModule, MatTableDataSource}​​​​​ from '@angular/material/table';
import {MatSnackBar} from '@angular/material';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

import { trigger, state, style, animate, transition, keyframes } from '@angular/animations';

const isSafari =  navigator.vendor &&
                  navigator.vendor.indexOf('Apple') > -1 &&
                  navigator.userAgent &&
                  navigator.userAgent.indexOf('CriOS') === -1 &&
                  navigator.userAgent.indexOf('FxiOS') === -1;

const isEdge = navigator && navigator.msSaveOrOpenBlob;
const addItemToCartIcon = '+  ';
const removeItemFromCartIcon = '--  ';

declare var Quagga: any;

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})

export class ProductListComponent implements OnInit {
  @ViewChild ('searchFilter')
  public searchFilter: SearchFieldComponent;
  public isLoadingError = false;
  public productsLoaded = false;
  public doneLoading = false;
  public products: ProductListItem[] = [];
  public documentFileItems: ActionDropdownItem[][] = [];
  public runConditionsItems: ActionDropdownItem[][] = [];
  private scrollToPoint: any;
  loading = false;
  public orderShell: Order;
  public cartItemCount = 0;
  private orderSearch: OrderSearch;
  public canPlaceOrder = false;
  public userCountry = 0;
  keys = Object.keys;
  languages = Languages;
  selectedLanguage = 'en-us';
  displayedColumns: string[] = ['name', 'ads', 'sds'];
  mobileDisplayedColumns: string[] = ['name', 'ads', 'group'];
  displayedGroup = false;
  public tableDataSource: MatTableDataSource<ProductListItem>;
  public innerWidth: any;
  @ViewChild (MatPaginator) paginator: MatPaginator;
  @ViewChild (MatSort) sort: MatSort;
  @Input() buttonClass = 'btn-primary';
  @Input() title: string;
  @Input() icon = 'description';
  @Input() mobileDropdown = 0;
  @Input() items: ActionDropdownItem[] = [];
  @Input() itemsRunConditions: ActionDropdownItem[] = [];
  constructor(
    private router: Router,
    private productService: ProductService,
    private documentService: DocumentService,
    private internalDataTransferService: InternalDataTransferService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private orderService: OrderService,
    private snackBar: MatSnackBar,
    private userService: UserService
    ) { }

  ngOnInit(): void {
    Quagga.stop();
    const storage: any = this.internalDataTransferService.getStorage();
    this.internalDataTransferService.setStorage(null);

    if (storage && storage.back) {
      this.loading = true;
      this.loadStateFromLocalStorage();
    } else {
      this.loadData();
    }
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  private loadData() {
    this.getProducts();
  }

  private saveStateToLocalStorage() {
    this.loadLanguage();
    localStorage.setItem('product-list-state', JSON.stringify({
      products: this.products,
      searchValue: this.searchFilter.getValue(),
      scrollToPoint: {
        x: window.pageXOffset || document.documentElement.scrollLeft,
        y: window.pageYOffset || document.documentElement.scrollTop
      },
      cartItemCount: this.canPlaceOrder ? this.cartItemCount : 0,
      orderSearch: this.canPlaceOrder ? this.orderSearch : null,
      canPlaceOrder: this.canPlaceOrder,
      selectedLanguage: this.selectedLanguage
    }));
  }

  onHeaderResized(event) {

  }

  private loadStateFromLocalStorage() {
    const dataString: string = localStorage.getItem('product-list-state');

    if (dataString && dataString !== '') {
      this.loading = false;
      const data: any = JSON.parse(dataString);
      this.products = data.products;
      this.searchFilter.setValue(data.searchValue);
      this.scrollToPoint = data.scrollToPoint;

      this.documentFileItems = [];
      this.runConditionsItems = [];
      this.products.forEach(product => {
        const docFile = this.getDocumentFileItems(product);
        const runCondition = this.getRunConditionItems(product);
        this.documentFileItems.push(docFile);
        this.runConditionsItems.push(runCondition);
      });

      this.canPlaceOrder = data.canPlaceOrder;
      this.cartItemCount = this.canPlaceOrder ? data.cartItemCount : 0;
      this.orderSearch = this.canPlaceOrder ? this.orderSearch : null;
      this.selectedLanguage = data.selectedLanguage;
    }
  }

  productListReady(isReady: boolean) {
    if (isReady && this.scrollToPoint) {
      window.scrollTo(this.scrollToPoint.x, this.scrollToPoint.y);
    }
  }

  onProductItemClick(id: string) {
    this.saveStateToLocalStorage();
    this.router.navigate(['/product', id]);
  }

  onSearchChange() {
    this.getProducts();
  }

  loadLanguage() {
    const fileLang: string = localStorage.getItem('fileLang');
    if ( localStorage.getItem('fileLang')) {
      this.selectedLanguage = fileLang;
    }
  }

  onRunConditionsClick(productId: string, runConditionId: string) {
    this.loadLanguage();
    this.loading = true;
    this.snackBar.open(this.translate.instant('ACTION.DOWNLOADING_DOCUMENT') + '...', '', {
      panelClass: 'snackbar-color'
    });
    this.documentService.getApplicationDataSheetFile(productId, runConditionId, this.selectedLanguage).subscribe(
      data => {
        this.downloadPdfFile(data, `${runConditionId}-ads`);
        this.loading = false;
        this.snackBar.dismiss();
      },
      () => {
        this.showNotification(productId, 'ADS');
        this.loading = false;
        this.snackBar.dismiss();
      }
    );
  }

  onSdsClick(id: string, productId: string) {
    // const url = this.documentService.getSafetyDataSheetUrl(productId, Languages.English);
    // window.open(url);
    const process = this.documentService.getSafetyDataSheetUrlSDS(id, productId, Languages.English);
    this.loading = true;

    this.snackBar.open(this.translate.instant('ACTION.DOWNLOADING_DOCUMENT') + '...', '', {
      panelClass: 'snackbar-color'
    });

    const env = this.documentService.getEnvUrl();
    // tslint:disable-next-line:max-line-length
    let url = `https://${env}buyat.ppg.com/EHSDocumentManagerPublic/documentSearch.aspx?FolderID1=87&SearchAllPublicFolders=True&CodeCondition=BeginsWith&CodeValue=${productId}&SuppressSearchControls=True`;

    process.subscribe(
      result => {
        if (result)  {
    // tslint:disable-next-line:max-line-length
          url = `https://${env}buyat.ppg.com/EHSDocumentManagerPublic/documentSearch.aspx?FolderID1=87&SearchAllPublicFolders=True&NameCondition=BeginsWith&NameValue=${productId}&SuppressSearchControls=True`;
        }
      },
      m => {
        console.log(' Failed load ');
      },
      () => {
        this.loading = false;
        this.snackBar.dismiss();
        window.open(url);
      }
      );
  }

  onTdsClick(productId: string) {
    this.loadLanguage();
    this.loading = true;
    this.documentService.getProductDataSheetFile(productId, this.selectedLanguage).subscribe(
      data => {
        this.downloadPdfFile(data, `${productId}-pds`);
        this.loading = false;
      },
      () => {
        this.showNotification(productId, 'PDS');
        this.loading = false;
      }
    );
  }

  onVocClick(urlFriendlyName: string) {
    this.loading = true;
    this.snackBar.open(this.translate.instant('ACTION.DOWNLOADING_DOCUMENT') + '...', '', {
      panelClass: 'snackbar-color'
    });
    this.documentService.getVocFile(urlFriendlyName).subscribe(
      data => {
        this.downloadPdfFile(data, `${urlFriendlyName}-voc`);
        this.loading = false;
        this.snackBar.dismiss();
      },
      () => {
        this.showNotificationVoCCoA('Please contact Milford Customer Service at pkgcustsvc@ppg.com for this document.');
        this.loading = false;
        this.snackBar.dismiss();
      }
    );
  }

  removeElement(lotNumber) {
    lotNumber.classList.remove('form-control-error');
  }

  onCoaClick(productId: string, lotNumber) {
    if (!lotNumber.value.trim()) {
      lotNumber.style.animationDuration = '0.5s';
      lotNumber.addEventListener('animationend', () => this.removeElement( lotNumber ));
      lotNumber.classList.add('form-control-error');
      return;
    }
    this.loading = true;
    this.snackBar.open( this.translate.instant('ACTION.DOWNLOADING_DOCUMENT') + '...', '', {
      panelClass: 'snackbar-color'
    });

    if (lotNumber.value.trim().startsWith('0812')) {

      alert('Please contact Milford Customer Service at pkgcustsvc@ppg.com for this CoA document');
      this.loading = false;
      this.snackBar.dismiss();

    } else {
      const productReplaced = productId.replace(/\//g, '');
      this.documentService.getCoaFile(productReplaced, lotNumber.value.trim()).subscribe(
        data => {
          this.downloadPdfFile(data, `${productReplaced}-coa`);
          this.loading = false;
          this.snackBar.dismiss();
        },
        () => {
          this.showNotificationVoCCoA('Please contact Milford Customer Service at pkgcustsvc@ppg.com for this document.');
          this.loading = false;
          this.snackBar.dismiss();
        }
      );
    }
  }

  addToCartClick(product: ProductListItem, cartLink) {
    if (product.isObsolete) {
      return;
    }

    if (cartLink.textContent === addItemToCartIcon) {
      const oi = new OrderItems();
      oi.productId = product.localId;
      oi.quantity = 1;
      oi.productCode = product.name;

      if (!this.orderShell.orderId || this.orderShell.orderId === '') {
        this.orderShell.orderId = '00000000-0000-0000-0000-000000000000';
        this.orderShell.orderStatus = 'CART_CREATED';
      }
      this.orderShell.orderItems = new Array<OrderItems>();
      this.orderShell.orderItems.push(oi);

      this.orderService.UpsertOrder(this.orderShell, 'ADD_ITEM').subscribe(
        data => {
          if (data.status.code === ResponseCode.Ok) {
            // we do not receive full order details after upsert. Do not replace order here.
            this.orderShell.orderId = data.order.orderId;
            this.orderShell.lineItemCount = data.order.lineItemCount;
            this.cartItemCount = data.order.lineItemCount;
            cartLink.id = data.order.orderItems[0].orderItemId;
            cartLink.textContent = removeItemFromCartIcon;
          }
        },
        err => {
        },
        () => {
        }
      );
    } else {
      this.orderShell.orderItems[0] = new OrderItems();
      this.orderShell.orderItems[0].orderItemId = cartLink.id;
      this.orderService.UpsertOrder(this.orderShell, 'DELETE_ITEM').subscribe(
        data => {
          if (data.status.code === ResponseCode.Ok) {
            this.orderShell.lineItemCount = data.order.lineItemCount;
            this.cartItemCount = data.order.lineItemCount;
            cartLink.removeAttribute('id');
            cartLink.textContent = addItemToCartIcon;
          }
        },
        err => {
        },
        () => {
        }
      );
    }
  }

  checkoutClick() {
    this.router.navigate(['/orders/checkout']);
  }

  private getProducts() {
    this.loading = true;
    const idOrDescription: string = this.searchFilter.getValue();
    if (this.productsLoaded) {
      this.isLoadingError = false;
      this.snackBar.open('Searching products...', '', {
        panelClass: 'snackbar-color'
      });
    } else {
      this.productsLoaded = true;
    }

    this.productService.getProductList(idOrDescription).subscribe(
      data => {
        console.log(data);
        if (data.status.code === ResponseCode.Ok) {
          this.products = data.productListItems;
          this.documentFileItems = [];
          this.runConditionsItems = [];
          this.products.forEach((product, index) => {
            product.index = index;
            this.documentFileItems.push(this.getDocumentFileItems(product));
            this.runConditionsItems.push(this.getRunConditionItems(product));
            product.cartOrderItemIdClient = 0;
          });
          this.tableDataSource = new MatTableDataSource<ProductListItem>(this.products);
          this.tableDataSource.sort = this.sort;
          this.tableDataSource.paginator = this.paginator;
          this.doneLoading = true;
          this.getUser();
          if (this.productsLoaded) {
            this.snackBar.dismiss();
          }
        } else {
          this.products = [];
          this.documentFileItems = [];
          this.runConditionsItems = [];
          this.isLoadingError = true;
          if (this.productsLoaded) {
            this.snackBar.dismiss();
          }
        }
      },
      error => {
        this.products = [];
        this.documentFileItems = [];
        this.runConditionsItems = [];
        this.isLoadingError = true;
        if (this.productsLoaded) {
          this.snackBar.dismiss();
        }
      }
    );
  }

  private getDocumentFileItems(product: ProductListItem): ActionDropdownItem[] {
    const documentFileItems: ActionDropdownItem[] = [];

    documentFileItems.push(
      { name: this.translate.instant('PRODUCT.END_USE'), action: () => { }}, // this.onProductItemClick(product.id); }},
      { name: this.translate.instant('PRODUCT.TDS'), action: () => { this.onTdsClick(product.id); }},
      { name: this.translate.instant('PRODUCT.SDS'), action: () => { this.onSdsClick(product.id, product.id); }},
      { name: this.translate.instant('PRODUCT.VOC'), action: () => { this.onVocClick(product.urlFriendlyName); } },
      { name: this.translate.instant('PRODUCT.SEE_MORE'), class: 'btn btn-primary drop-button',
      action: () => { this.onProductItemClick(product.id); } }
    );

    return documentFileItems;
  }

  getRunConditionItems(product: ProductListItem): ActionDropdownItem[] {
    const runConditionsItems: ActionDropdownItem[] = [];

    if (product.runConditions.length === 0) {
      runConditionsItems.push({name: product.name, action: () => {
        this.onTdsClick(product.id);
      }});
    }

    product.runConditions.forEach(element => {
      // if(element.name.substring(element.name.lastIndexOf("-")+1) ){

      // }
      runConditionsItems.push({name: element.name, action: () => {
        this.onRunConditionsClick(product.id, element.id);
      }});
    });
    return runConditionsItems;
  }


  private downloadPdfFile(data: Blob, name?: string) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const blobUrl = window.URL.createObjectURL(blob);

    if (isEdge) {
      window.navigator.msSaveOrOpenBlob(blob, `${name}.pdf`);
    } else if (isSafari) {
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = `${name}.pdf`;
      link.click();
    } else {
      window.open(blobUrl);
    }
  }

  private showNotification(productId: string, fileType: string) {
    this.toastr.error(`${this.translate.instant('OTHER.ERROR_DOWNLOADING_ID', {value: fileType})}: ${productId}`);
  }

  private showNotificationVoCCoA(message: string) {
    this.toastr.error(`${message}`);
  }

  private getOrder() {
    this.loading = true;
    this.orderService.GetOrder(this.orderSearch).subscribe(
      data => {
        if (data.status.code === ResponseCode.Ok) {
          this.orderShell = new Order();
          if (data.order && data.order.length > 0) {
            this.orderShell = data.order[0];
            this.setAllProductsOrderItemId();
            this.cartItemCount = this.orderShell.orderItems.length;
          }
        } else {
          this.orderShell = new Order();
        }
      },
      err => {
      },
      () => {
        this.loading = false;
      }
    );
  }

  private setAllProductsOrderItemId() {
    this.products.forEach(product => {
      this.setProductOrderItemId(product);
    });
  }

  private setProductOrderItemId(product: ProductListItem) {
    if (product.cartOrderItemIdClient <= 0 && this.orderShell.orderItems.length > 0) {
      const oi = this.orderShell.orderItems.find(i => i.productCode === product.name);

      if (product.isObsolete) {
        product.cartOrderItemIdClient = 0;
      } else {
        product.cartOrderItemIdClient = oi != null ? oi.orderItemId : 0;
      }
    }
  }

  public displayCartFunction(cartOrderItemId: number) {
    if (cartOrderItemId > 0) {
      return removeItemFromCartIcon;
    } else {
      return addItemToCartIcon;
    }
  }

  private getUser() {
    this.userService.getUser().subscribe(
      data => {
        if (data) {
          this.canPlaceOrder = data.user.canPlaceOrders;
          if (data.user.userRegion === 1) {
            if (this.displayedColumns.indexOf('voc') < 0) {
              this.displayedColumns.push('voc');
              this.displayedColumns.push('coa');
              this.displayedGroup = true;
            }
          }
          if (this.canPlaceOrder) {
            this.orderSearch = new OrderSearch();
            this.orderShell = new Order();
            this.orderSearch.orderStatus = 'CART_CREATED';
            this.getOrder();  // find existing cart
          } else {
            this.loading = false;
          }
        }
      }
    );
  }

  setLanguage(language) {
    this.selectedLanguage = language;
    console.log(language);
  }

}
