import { OrderItems } from './OrderItems';
import { OrderAddress } from './OrderAddress';

export class Order {
  public orderId: string;

  public orderNumber: number;

  public customerId: number;

  public orderStatus: string;

  public createdByUserId: number;

  public lineItemCount: number;

  public orderItems: OrderItems[] = [];

  public orderDate: string;

  public requiredDate: string;

  public customerName: string;

  public poNumber: string;

  public address: OrderAddress;
}
