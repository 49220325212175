import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of, throwError } from 'rxjs';
import { Injector, Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EnvironmentService } from '../services/EnvironmentService';
import { Environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private oidcSecurityService: OidcSecurityService;

    constructor(
        private injector: Injector,
        private router: Router,
        private environmentService: EnvironmentService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let requestToForward = req;

        if (this.oidcSecurityService === undefined) {
            this.oidcSecurityService = this.injector.get(OidcSecurityService);
        }

        if (this.oidcSecurityService !== undefined) {
            const token = this.oidcSecurityService.getToken();
            if (token !== '') {
                requestToForward = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }
        }

        return next.handle(requestToForward).pipe(
            catchError((error: any) => {
                if (error instanceof HttpErrorResponse) {
                    const environment: Environment = this.environmentService.getEnvironment();

                    switch (error.status) {
                        case 401:
                            this.router.navigate([environment.authorizationConfig.common.unauthorizedRoute]);
                            return of(error as any);
                        case 403:
                            this.router.navigate(
                              [environment.authorizationConfig.common.forbiddenRoute],
                              { state: { error: error.error } }
                            );
                            return of(error as any);
                    }
                }

                return throwError(error);
            })
        );
    }
}
