import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[appMin][formControlName],[appMin][formControl],[appMin][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinValidatorDirective, multi: true }]
})
export class MinValidatorDirective {
  @Input()
  appMin: number;

  validate(c: FormControl): { [key: string]: any } {
    const v = c.value;
    return (v < this.appMin) ? { appMin: true } : null;
  }

}
