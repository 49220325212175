import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { AuthService } from '../services/AuthService';
import { Environment } from 'src/environments/environment';
import { EnvironmentService } from '../services/EnvironmentService';
import { UserService } from '../services/UserService';
import { User } from '../models/User';
import { UserResponse } from '../models/responses/UserResponse';

@Injectable()
export class AuthorizationGuard implements CanActivate {
  private route: ActivatedRouteSnapshot;

  constructor(
    private router: Router,
    private authService: AuthService,
    private environmentService: EnvironmentService,
    private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.route = route;
    return this.checkUser();
  }

  private checkUser(): Observable<boolean> {
    return this.authService.getIsLoggedIn().pipe(
      tap((isAuthorized: boolean) => {
        if (!isAuthorized) {
          const environment: Environment = this.environmentService.getEnvironment();
          this.router.navigate([environment.authorizationConfig.common.unauthorizedRoute]);
        } else {
          this.checkOrderRole();
        }
      })
    );
  }

  private checkOrderRole() {
    if (this.route.data.role === 'order') {
      this.userService.getUser().subscribe(
        data => {
          if (data && data.user && !data.user.canPlaceOrders) {
            const environment: Environment = this.environmentService.getEnvironment();
            this.router.navigate([environment.authorizationConfig.common.unauthorizedRoute]);
          }
        },
        () => { }
      );
    }
  }
}
