import { Injectable } from '../../../node_modules/@angular/core';
import { Observable } from '../../../node_modules/rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from './EnvironmentService';
import { Params } from '@angular/router';

@Injectable()
export class ServiceBase {
    private baseURL: string;

    constructor(private http: HttpClient, private environmentService: EnvironmentService) {
        this.baseURL = this.environmentService.getEnvironment().apiUrl;
     }

    public get<R>(apiUrl: string, params?: HttpParams): Observable<R> {
        return this.http.get<R>(this.baseURL + apiUrl, {
            params
        });
    }

    public getBlob(apiUrl: string): Observable<Blob> {
        return this.http.get(this.baseURL + apiUrl, {
            responseType: 'blob'
        });
    }

    public postWithOptions<T, R>(apiUrl: string, body: T, h?: HttpHeaders, p?: Params): Observable<R> {
        return this.http.post<R>(this.baseURL + apiUrl, body, { headers: h, params: p });
    }

    public post<T, R>(apiUrl: string, body: T): Observable<R> {
        return this.http.post<R>(this.baseURL + apiUrl, body);
    }
}
