import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { EnvironmentService } from './services/EnvironmentService';
import { AuthService } from './services/AuthService';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/User';
import { UserService } from 'src/app/services/UserService';
import { Languages } from './models/Languages.enum';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public isIframe: boolean;
  public loggedIn: boolean;
  private subscription: Subscription;
  public user: User = null;

  constructor(
    translate: TranslateService,
    private router: Router,
    private env: EnvironmentService,
    private authService: AuthService,
    private userService: UserService) {

    const navEndEvent = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );

    const environment = this.env.getEnvironment();
    const gaId: string = environment.googleAnalyticsId;

    if (environment.production) {
      const scriptBody = document.createElement('script');
      scriptBody.textContent =
        `window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());`;

      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;

      const first = document.head.childNodes[0];
      const node = document.head.insertBefore(scriptBody, first);
      document.head.insertBefore(script, node);
    }

    navEndEvent.subscribe(
      (e: NavigationEnd) => {
        if (environment.production && this.checkRoute(e)) {
          gtag('config', `${gaId}`, { page_path: e.urlAfterRedirects });
        }
      },
      () => { }
    );

    const keys: (keyof typeof Languages)[] = Object.keys(Languages) as (keyof typeof Languages)[];
    for (const key of keys) {
      const locale: string = Languages[key];
      translate.addLangs([locale] );
    }

    if ( localStorage.getItem('siteLang'))  {
      translate.setDefaultLang(localStorage.getItem('siteLang'));
      translate.use(localStorage.getItem('siteLang'));
    } else {
      translate.setDefaultLang('en-us');
      translate.use(this.detectBrowserLanguage());
    }
    if (window.location) {
      const url: string = window.location.toString();
      const indexAccessToken: number = url.indexOf('#access_token=');
      const indexState: number = url.indexOf('#state=');
      const index = indexAccessToken >= 0 ? indexAccessToken : indexState;

      if (index >= 0) {
        const hash: string = url.substr(index, url.length - index);
        window.location.href = `${window.location.origin}/#/redirect${hash}`;
      }
    }
  }

  ngOnInit() {
      this.subscription = this.authService.getIsLoggedIn().subscribe(
      (isLoggedIn: boolean) => {
        if (isLoggedIn && !this.loggedIn) {
          this.getUser();
        }
        this.loggedIn = isLoggedIn;
      }
    );
  }

  detectBrowserLanguage() {
    const language = navigator.languages && navigator.languages[0] || navigator.language;
    return language.split('-')[0];
  }

  private checkRoute(e: NavigationEnd): boolean {
    if (e.urlAfterRedirects && e.urlAfterRedirects.indexOf('/redirect') >= 0) {
      return false;
    } else {
      return true;
    }
  }

  private getUser() {
    this.userService.getUser().subscribe(
    data => {
      if (data) {
        this.user = data.user;
        }
      }
    );
  }

  public handleLogout() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.authService.signOut();
  }

   enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}
}
