import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { AppRoutingModule } from './app-routing-module';
import { ServiceBase } from './services/ServiceBase';
import { ScanningComponent } from './components/scanning/scanning.component';
import { ScanningTestComponent } from './components/scanning-test/scanning.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductService } from './services/ProductService';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ActionDropdownListComponent } from './components/action-dropdown-list/action-dropdown-list.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { ErrorComponent } from './components/error/error.component';
import { InternalDataTransferService } from './services/InternalDataTransferService';
import { PreviousRouteService } from './services/PreviousRouterService';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
import { AuthorizationGuard } from './guards/AuthorizationGuard';
import { AuthInterceptor } from './interceptors/AuthInterceptor';
import { AuthService, AuthType } from './services/AuthService';
import { RedirectComponent } from './components/redirect/redirect.component';
import { EnvironmentService } from './services/EnvironmentService';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DocumentService } from './services/DocumentService';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NoProductComponent } from './components/no-product/no-product.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { OrderService } from './services/OrderService';
import { OrderSearchComponent } from './components/order-search/order-search.component';
import { MatTableModule, MatSortModule, MatPaginatorModule, MatProgressSpinnerModule,
  MatFormFieldModule, MatNativeDateModule, MatInputModule, MatDialogModule, MatSnackBar, MatSnackBarModule } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule} from '@angular/forms';
import { MinValidatorDirective } from './directives/validators/min-validator.directive';
import { UserService } from './services/UserService';
import { PassResetComponent } from './components/pass-reset/pass-reset.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/locale/');
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    HeaderComponent,
    ActionDropdownListComponent,
    NavigationComponent,
    ProductDetailsComponent,
    RedirectComponent,
    ProductListComponent,
    ScanningComponent,
    ScanningTestComponent,
    SearchFieldComponent,
    SignInComponent,
    SpinnerComponent,
    NoProductComponent,
    CheckoutComponent,
    OrderSearchComponent,
    MinValidatorDirective,
    PassResetComponent
  ],
  imports: [
    AppRoutingModule,
    AuthModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    ZXingScannerModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatProgressSpinnerModule,
    MatTableModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatSortModule
  ],
  providers: [
    AuthorizationGuard,
    AuthService,
    DocumentService,
    EnvironmentService,
    InternalDataTransferService,
    OidcConfigService,
    PreviousRouteService,
    ProductService,
    OrderService,
    UserService,
    ServiceBase,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(private authService: AuthService) {

    const matches = /#\/sign-in\?authtype=([A-Za-z0-9]+)/.exec(location.hash);
    let authType = '';

    if (matches && matches.length > 1) {
      authType = matches[1];
    }

    if (authType === 'adfs' || authType === 'b2c') {
      this.authService.configure(authType === 'adfs' ? AuthType.ADFS : AuthType.ADB2C);
    } else {
      this.authService.configure();
    }
  }
}
