import { Injectable } from '@angular/core';

@Injectable()
export class InternalDataTransferService {
    private storage: any;
    public constructor() { }

    public setStorage(storage: any) {
        this.storage = storage;
    }

    public getStorage(): any {
        return this.storage;
    }
}
