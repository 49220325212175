import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderSearch } from 'src/app/models/OrderSearch';
import { OrderService } from 'src/app/services/OrderService';
import { ResponseCode } from 'src/app/models/responses/ResponseCode';
import { Order } from 'src/app/models/Order';
import { OrderItems } from 'src/app/models/OrderItems';
import { Product } from 'src/app/models/Product';

declare var Quagga: any;

export interface OrderHistory {
  orderNumber: number;
  orderDate: Date;
  purchaseOrderNumber: string;
  total: number;
  products: string[];
}

@Component({
  selector: 'app-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss']
})
export class OrderSearchComponent implements OnInit {
  public loading = false;
  private orderSearch: OrderSearch;
  public tableData: OrderHistory[] = [];
  public hidePrice = true;

  displayedColumns: string[] = ['orderNumberCol', 'productsCol', 'orderDateCol', 'purchaseOrderNumberCol'];

  constructor(
    private router: Router,
    private orderService: OrderService
  ) { }

  ngOnInit() {
    Quagga.stop();
    if (!this.hidePrice) {
      this.displayedColumns.push('totalCol');
    }
    this.orderSearch = new OrderSearch();
    this.orderSearch.orderStatus = 'PLACED';
    this.getOrder();
  }

  private getOrder() {
    this.loading = true;

    this.orderService.GetOrder(this.orderSearch).subscribe(
      data => {
        if (data.status.code === ResponseCode.Ok && (data.order != null && data.order.length > 0)) {
          this.loadElementData(data.order);
        }
      },
      err => {
      },
      () => {
        this.loading = false;
      }
    );
  }

  private loadElementData(orders: Order[]) {

    orders.forEach(order => {
      let tempTotal = 0;
      const tempProduct: string[] = [];

      const oh: OrderHistory = {
        orderNumber: order.orderNumber,
        orderDate: new Date(order.orderDate),
        purchaseOrderNumber: order.poNumber,
        total: 0,
        products: []
      };

      order.orderItems.forEach(oi => {
        tempTotal = tempTotal + this.DecimalFormatted((this.DecimalFormatted(oi.quantity, 2) * this.DecimalFormatted(oi.price, 2)), 2);
        tempProduct.push(oi.productCode);
      });
      oh.total = tempTotal;
      oh.products = tempProduct;

      this.tableData.push(oh);
    });


  }

  NumberToLocale(num: number, minFractionDigits: number, maxFractionDigits: number): string {
    const tempNum: number = isNaN(num) ? 0 : num;
    return tempNum.toLocaleString('en-US', { maximumFractionDigits: maxFractionDigits, minimumFractionDigits: minFractionDigits });
  }

  DecimalFormatted(num: number, decimalPlace: number): number {
    return +((+num).toFixed(decimalPlace));
  }

}
